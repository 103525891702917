<template>
    <SmartForm base_url="/document" base_path="/document" form_type="edit" after_update_url="/document/view/">
    </SmartForm>
</template>

<script>
import SmartForm from "@/view/components/SmartForm.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    components: {
        SmartForm,
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Documenti", route: "/document/index" },
            { title: "Modifica Documento" }
        ]);
    },
};
</script>
